import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";

const MODE_APP_WEBVIEW = "appwebview";
export const NAccRegistrationWrapper = () => {
  const location = useLocation();
  const { storeID } = useParams();
  const query = new URLSearchParams(location.search);
  const phone = query.get("phone");
  const mode = query.get("mode");
  const loc = query.get("location");
  const timestamp = Date.now();
  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (
        e.key === `agnet_opened_${storeID}_${timestamp}` &&
        !e.oldValue &&
        e.newValue
      ) {
        let value = e.newValue;
        localStorage.removeItem(`agnet_opened_${storeID}_${timestamp}`);
        if(mode && mode === MODE_APP_WEBVIEW){
          window.flutter_inappwebview.callHandler("Response", value);
        }else{
          Response.postMessage(value);
        }
      }
    });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>Ninja Agnet Registration</title>
        <meta charSet="utf-8" />
      </Helmet>
      <div style={{ height: "100vh" }}>
        <iframe
          title="Ninja Agnet Registration"
          src={`${process.env.REACT_APP_NINJA_AGNET_URL}/ninjagro?phonenumber=${phone}&location=${loc? loc : ""}&callbackurl=${process.env.REACT_APP_SELF_URL}/naccount/store/${storeID}/onregistration/${timestamp}`}
          frameBorder="0"
          width="100%"
          height="100%"
          scrolling="yes"
          allow="geolocation"
        />
      </div>
    </>
  );
};
export default NAccRegistrationWrapper;
