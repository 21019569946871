import styled from "styled-components";
import { useTheme } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

import WarningRoundedIcon from "@material-ui/icons/WarningRounded";
import PaymentInfo from "./components/PaymentInfo";

const Container = styled.div`
  color: #1a1a1a;
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
  min-height: calc(
    var(--vh, 1vh) * 100
  ); // Check definition in public/index.html
  @media screen and (min-width: 768px) {
    background-color: #f7f7f7;
    text-align: left;
  }

  header {
    padding: 0.75rem;
    box-shadow: 0 1px 0px 0 rgba(0, 0, 0, 0.25);
    text-align: center;
    font-weight: bold;
    font-size: 16pt;
  }
  .loader {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  footer {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background-color: ${({ theme }) => theme.palette.primary.main};
    font-size: 10pt;
    padding: 0.5rem;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }
`;

const InvalidLink = styled.main`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PaymentScreen = () => {
  const theme = useTheme();
  const { payID } = useParams();
  const [paymentDetails, setPaymentDetails] = useState({});
  const [deeplink, setDeeplink] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (payID) {
      axios
        .get(`https://api.shoopy.in/api/v1/pay-links/token/${payID}`)
        .then((response) => {
          const { data } = response;
          document.title = `Payment Request - ${data.store_name}`;
          setPaymentDetails(data);
          setLoading(false);
          const pa = data.user_vpa;
          const pn = data.user_name;
          const tn = data.note || `Payment to store ${data.store_name}`;
          const tr = data.id;
          const cu = "INR";
          const mc = "5411";
	  const am = data.amount;
          const url = "";
          setDeeplink(
            `pay?pa=${pa}&pn=${pn}&mc=${mc}&tn=${tn}&tr=${tr}&cu=${cu}&am=${am}&url=${url}`
          );
        })
        .catch((error) => console.log(error));
    }
  }, [payID]);

  return (
    <Container theme={theme}>
      <header>Payment Request</header>
      {loading ? (
        <main className="loader">
          <CircularProgress />
        </main>
      ) : paymentDetails ? (
        <PaymentInfo deeplink={deeplink} payment={paymentDetails} />
      ) : (
        <InvalidLink>
          <WarningRoundedIcon />
          <div>
            Link invalid or expired. Please contact the seller for a new payment
            link.
          </div>
        </InvalidLink>
      )}
      <footer>
        Powered by {paymentDetails.premium ? paymentDetails.store_name : "Shoopy"}
      </footer>
    </Container>
  );
};

export default PaymentScreen;
