import { CircularProgress, Container } from "@material-ui/core";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";
import Toast from "../../common/components/Toast";
import { container } from "../../styles/common";

const NWalletOnPayment = () => {
  const location = useLocation();
  const { storeID, orderID, timestamp } = useParams();
  const query = new URLSearchParams(location.search);
  const [toast, setToast] = useState(false);
  const [message, setMessage] = useState("");
  const loanId = query.get("loanid");
  const amountPaid = query.get("amountPaid");
  const transactionId = query.get("invoiceid");
  const paymentMode = query.get("paymentmode");
  const status = query.get("status");
  const [loading, setLoading] = useState(true);
  const pmtStatus = status != null ? status : "success";
  const [severity, setSeverity] = useState("success");
  const capturePayment = async () => {
    let resp = {};
    let payment = {
      amount: amountPaid,
      order_id: parseInt(orderID),
      payment_type: loanId ? "loan" : paymentMode,
      reference_id: loanId ? loanId : transactionId,
      gw_txn_id: loanId ? loanId : transactionId,
      gw_name : "ninjacart"
    };
    try {
      let response = await axios.post(
        `https://api.shoopy.in/api/v1/org/store/${storeID}/order/${orderID}/ninja-capture-payment`,
        payment,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${process.env.REACT_APP_TRUSTED_TOKEN}`,
          },
        }
      );
      resp.payment_id = response.data.payload.payment_id;
    } catch (err) {
      resp.error = err.response.data.message;
    }
    return resp;
  };

  useEffect(() => {
    if (pmtStatus === "success") {
      setLoading(true);
      capturePayment().then((resp) => {
        setLoading(false);
        if (resp.payment_id) {
        } else {
          setToast(true);
          setMessage(resp.error);
          setSeverity("error");
        }
        setTimeout(function () {
          localStorage.setItem(`agnet_wallet_opened_${storeID}_${timestamp}`, "success");
        }, 1000);
      });
    } else {
      setToast(true);
      setMessage("Payment failed");
      setSeverity("error");
      setTimeout(function () {
        localStorage.setItem(`agnet_wallet_opened_${storeID}_${timestamp}`, "failed");
      }, 1000);
    }
  }, []);
  return (
    <>
      <Helmet>
        <title>Processing payment</title>
        <meta charSet="utf-8" />
      </Helmet>
      <Container className={container}>
        {loading && (
          <div
            style={{
              height: "89vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="secondary" />
            <p
              style={{
                fontSize: 16,
                fontWeight: 400,
                marginTop: 18,
                textAlign: "center",
                lineHeight: "28px",
              }}
            >
              Your invoice payment is being captured!
              <br /> Please don't press back or refresh this page.
            </p>
          </div>
        )}
        <Toast
          open={toast}
          close={setToast}
          message={message}
          severity={severity}
        />
      </Container>
    </>
  );
};
export default NWalletOnPayment;
