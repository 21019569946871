import styled from "styled-components";
import CopyIcon from "@material-ui/icons/FileCopyOutlined";
import Snackbar from "@material-ui/core/Snackbar";

import QRCode from "qrcode.react";
import { isAndroid, isMobile } from "react-device-detect";
import {
  gpay,
  phonepe,
  paytm,
  shoopy,
  whatsapp,
  upi,
} from "../../../assets/images";
import { useState } from "react";

const MobileContainer = styled.main`
  flex: 1;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: ${isMobile ? "flex-start" : "center"};
  @media screen and (min-width: 768px) {
    display: none;
  }

  .price {
    font-size: 28pt;
    font-weight: bold;
  }

  .store {
    font-size: 15pt;
    span {
      font-weight: bold;
    }
    margin-bottom: 0.5rem;
  }

  .qr {
    width: 100%;
    height: auto;
    max-width: 300px;
    display: block;
    padding: 1rem;
    margin: 0 auto;
  }
  .upi {
    padding: 0 1rem ${isMobile ? "0.75rem" : "0"} 1rem;
    margin: 0 -1rem 1rem -1rem;
    border-bottom: ${isMobile ? "1px" : "0px"} solid #d9d9d9;
    user-select: none;

    div {
      display: flex;
      align-items: center;
      width: fit-content;
      margin: 0 auto;
      cursor: pointer;
      span {
        text-decoration: underline;
        margin-right: 0.5rem;
      }
    }
  }
  .modes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    /* width: 95%; */
    margin: 0.75rem auto 0.75rem auto;

    img {
      width: 56px;
      height: auto;
      border-radius: 16px;
      margin: 0 0.5rem 0.75rem 0.5rem;
      filter: drop-shadow(-3px -3px 3px #ffffff)
        drop-shadow(3px 3px 3px #d9d9d9);
      transition: filter 200ms ease;

      &:active {
        filter: none;
      }
    }
  }
  .note {
    color: #808080;
    text-align: left;
    font-size: 10pt;
    text-align: ${isMobile ? "left" : "center"};
  }
`;

const DesktopContainer = styled.main`
  flex: 1;
  padding: 3rem 1rem 1rem 1rem;
  color: #1a1a1a;
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
  }

  section {
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 2rem 3rem;
    background-color: #ffffff;
    display: grid;
    grid-template-columns: 4fr 3fr;
    grid-row-gap: 1rem;
    grid-column-gap: 3rem;
    border-radius: 32px;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14),
      0 2px 1px -1px rgba(0, 0, 0, 0.2);
    .note {
      color: #999999;
      text-align: left;
      font-size: 10pt;
      grid-column: 1/-1;
      span {
        font-weight: 600;
      }
    }

    .left {
      .qr {
        width: 100%;
        height: auto;
        /* max-width: 300px; */
        display: block;
        margin: 0 auto;
      }
    }

    .right {
      display: flex;
      flex-direction: column;

      .price {
        font-size: 28pt;
        padding-bottom: 0.5em;
        margin-bottom: 0.5em;
        font-weight: bold;
        border-bottom: 1px solid #ccc;
      }

      .store {
        font-size: 13pt;
        span {
          font-weight: bold;
        }
        margin-bottom: 1.5em;
      }

      .upi {
        user-select: none;
        margin-bottom: 1rem;

        div {
          display: flex;
          align-items: center;
          font-weight: bold;
          cursor: pointer;
          span {
            margin-right: 0.5rem;
          }
        }
      }
      .modes {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;

        img {
          width: 40px;
          height: auto;
          border-radius: 8px;
          margin-right: 1rem;
          margin-bottom: 0.75rem;
          filter: drop-shadow(-3px -3px 3px #ffffff)
            drop-shadow(3px 3px 3px #d9d9d9);
          transition: filter 200ms ease;

          &:last-child {
            margin-right: 0;
          }

          &:active {
            filter: none;
          }
        }
      }

      .spacer {
        flex: 1;
      }

      article {
        display: grid;
        grid-template-columns: 48px 1fr;
        grid-gap: 1rem;
        width: fit-content;
        place-items: center;
        font-size: 10pt;
        img {
          height: 44px;
        }
        b {
          font-size: 14pt;
        }
      }
    }
  }
`;

const PaymentInfo = ({ deeplink, payment }) => {
  const [open, setOpen] = useState(false);
  const handleSnackbarClose = (event, reason) => {
    setOpen(false);
  };
  const handleGpay = () => {
    //"phonepe" === e.data.payment_method ? r = "Android" === Object(Qt.a)() ? "intent://{deeplink}#Intent;scheme=upi;package=com.phonepe.app;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.phonepe.app&hl=en_IN;end" : "phonepe://{deeplink}" : "googlepay" === e.data.payment_method ? r = "Android" === Object(Qt.a)() ? "intent://{deeplink}#Intent;scheme=upi;package=com.google.android.apps.nbu.paisa.user;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user;end" : "tez://upi/{deeplink}" : "paytmupi" === e.data.payment_method ? r = "Android" === Object(Qt.a)() ? "intent://{deeplink}#Intent;scheme=upi;package=net.one97.paytm;S.browser_fallback_url=https://play.google.com/store/apps/details?id=net.one97.paytm;end" : "paytmmp://upi/{deeplink}" : "whatsapp" === e.data.payment_method && (r = "Android" === Object(Qt.a)() ? "intent://{deeplink}#Intent;scheme=upi;package=com.whatsapp;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.whatsapp;end" : "whatsapp://{deepLink}"),
    if (isAndroid) {
      window.location.assign(
        `intent://${deeplink}#Intent;scheme=upi;package=com.google.android.apps.nbu.paisa.user;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.google.android.apps.nbu.paisa.user;end`
      );
    } else {
      window.location.assign(`tez://upi/${deeplink}`);
    }
  };
  const handlePhonepe = () => {
    window.location.assign(`phonepe://${deeplink}`);
  };
  const handlePaytm = () => {
    if (isAndroid) {
      window.location.assign(
        `intent://${deeplink}#Intent;scheme=upi;package=net.one97.paytm;S.browser_fallback_url=https://play.google.com/store/apps/details?id=net.one97.paytm;end`
      );
    } else {
      window.location.assign(`paytmmp://upi/${deeplink}`);
    }
  };
  const handleWhatsapp = () => {
    if (isAndroid) {
      window.location.assign(
        `intent://${deeplink}#Intent;scheme=upi;package=com.whatsapp;S.browser_fallback_url=https://play.google.com/store/apps/details?id=com.whatsapp;end`
      );
    } else {
      window.location.assign(`whatsapp://${deeplink}`);
    }
  };
  const handleGenericUpi = () => {
    window.location.assign(`upi://${deeplink}`);
  };

  return (
    <>
      <MobileContainer isMobile={isMobile}>
        <div className="store">
          by <span>{payment.store_name}</span>
        </div>
        <div className="price">
          {new Intl.NumberFormat("en-IN", {
            style: "currency",
            currency: "INR",
          }).format(payment.amount)}
        </div>
        <QRCode className="qr" renderAs="svg" value={`upi://${deeplink}`} />
        <div className="upi">
          <b>UPI ID</b>
          <div
            onClick={() => {
              navigator.clipboard.writeText(payment.user_vpa);
              setOpen(true);
            }}
          >
            <span>{payment.user_vpa}</span>{" "}
            <CopyIcon fontSize="small" style={{ color: "#242424" }} />
          </div>
        </div>
        {isMobile && (
          <>
            <div>Pay using</div>
            <div className="modes">
              <img src={gpay} alt="Google Pay" onClick={handleGpay} />
              <img src={phonepe} alt="PhonePe" onClick={handlePhonepe} />
              <img src={paytm} alt="PayTM" onClick={handlePaytm} />
              <img
                src={whatsapp}
                alt="Whatsapp Payments"
                onClick={handleWhatsapp}
              />
              <img src={upi} alt="Other" onClick={handleGenericUpi} />
            </div>
          </>
        )}
        {isMobile ? (
          <div className="note">
            NOTE: If UPI apps are not opening then scan the QR Code from any UPI
            app directly.
          </div>
        ) : (
          <div className="note">
            NOTE: Use any UPI app and scan this QR Code to pay.
          </div>
        )}
        <Snackbar
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          open={open}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message="UPI ID copied to clipboard"
        />
      </MobileContainer>
      <DesktopContainer>
        <section>
          <div className="left">
            <QRCode className="qr" renderAs="svg" value={`upi://${deeplink}`} />
          </div>
          <div className="right">
            <div className="store">
              Requested by
              <br />
              <span>{payment.store_name}</span>
            </div>

            <div className="price">
              {new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(payment.amount)}
            </div>

            <div className="upi">
              UPI ID
              <div
                onClick={() => {
                  navigator.clipboard.writeText(payment.user_vpa);
                  setOpen(true);
                }}
              >
                <span>{payment.user_vpa}</span>{" "}
                <CopyIcon fontSize="small" style={{ color: "#242424" }} />
              </div>
            </div>
            {isMobile && (
              <div className="modes">
                <img src={gpay} alt="Google Pay" onClick={handleGpay} />
                <img src={phonepe} alt="PhonePe" onClick={handlePhonepe} />
                <img src={paytm} alt="PayTM" onClick={handlePaytm} />
                <img
                  src={whatsapp}
                  alt="Whatsapp Payments"
                  onClick={handleWhatsapp}
                />
                <img src={upi} alt="Other" onClick={handleGenericUpi} />
              </div>
            )}
            <div className="spacer"></div>
            <article>
              <img src={payment.premium ? (payment.store_logo 
                ? payment.store_logo : shoopy) : shoopy} />
              <div>
                Powered by
                <br />
                <b>
                  {payment.premium
                    ? payment.store_name
                    : "Shoopy"}
                </b>
              </div>
            </article>
          </div>
          {isMobile ? (
            <div className="note">
              NOTE
              <br />
              <span>
                If UPI apps are not opening then scan the QR Code from any UPI
                app directly.
              </span>
            </div>
          ) : (
            <div className="note">
              NOTE
              <br />
              <span>Use any UPI app and scan this QR Code to pay.</span>
            </div>
          )}
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            open={open}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            message="UPI ID copied to clipboard"
          />
        </section>
      </DesktopContainer>
    </>
  );
};

export default PaymentInfo;
