import { ThemeProvider } from "@material-ui/core/styles/";
import theme from "./theme";
import { createGlobalStyle } from "styled-components";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import PaymentScreen from "./screens/PaymentScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import NWalletOpen from "./screens/Ninjacart/NWalletWrapper";
import NWalletOnPayment from "./screens/Ninjacart/NWalletOnPayment";
import NAccOnRegistration from "./screens/Ninjacart/NAccOnRegistration";
import NAccRegistrationWrapper  from "./screens/Ninjacart/NAccRegistrationWrapper";

const GlobalStyle = createGlobalStyle`
  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Noto Sans", sans-serif;
  }
`;

function App() {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <Switch>
          <Route path="/upi/:payID">
            <PaymentScreen />
          </Route>
          <Route path="/nwallet/store/:storeID/order/:orderID">
            <NWalletOpen />
          </Route>
          <Route path="/nwallet/store/:storeID/onpayment/:orderID/:timestamp">
            <NWalletOnPayment />
          </Route>
          <Route path="/naccount/store/:storeID/registration">
            <NAccRegistrationWrapper />
          </Route>
          <Route path="/naccount/store/:storeID/onregistration/:timestamp">
            <NAccOnRegistration />
          </Route>
          <Route>
            <NotFoundScreen />
          </Route>
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
