import { useTheme } from "@material-ui/core/styles";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  min-height: 100vh;
  min-height: calc(
    var(--vh, 1vh) * 100
  ); // Check definition in public/index.html

  main {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    font-size: 16pt;

    span {
      font-size: 12pt;
    }
  }

  footer {
    color: ${({ theme }) => theme.palette.primary.contrastText};
    background-color: ${({ theme }) => theme.palette.primary.main};
    font-size: 10pt;
    padding: 0.5rem;
  }
`;
const NotFoundScreen = () => {
  const theme = useTheme();

  return (
    <Container theme={theme}>
      <main>
        <div>404 Not Found</div>
        <span>
          <br />
          Please get the payment link through a seller
        </span>
      </main>
      <footer>Powered by Shoopy</footer>
    </Container>
  );
};

export default NotFoundScreen;
