import React from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation, useParams } from "react-router-dom";

const MODE_APP_WEBVIEW = "appwebview";
const NWalletWrapper = () => {
  const location = useLocation();
  const { storeID, orderID } = useParams();
  const query = new URLSearchParams(location.search);
  const code = query.get("code");
  const amount = query.get("amount");
  const invoiceId=query.get("invoiceid");
  const mode=query.get("mode");
  const timestamp = Date.now();
  useEffect(() => {
    window.addEventListener("storage", (e) => {
      if (
        e.key === `agnet_wallet_opened_${storeID}_${timestamp}` &&
        !e.oldValue &&
        e.newValue
      ) {
        let value = e.newValue;
        localStorage.removeItem(`agnet_wallet_opened_${storeID}_${timestamp}`);
        if(mode && mode === MODE_APP_WEBVIEW){
          window.flutter_inappwebview.callHandler("Response", value);
        }else{
          Response.postMessage(value);
        }
      }
    });
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <Helmet>
        <title>Ninjacart Wallet</title>
        <meta charSet="utf-8" />
      </Helmet>
      <div style={{ height: "100vh" }}>
        <iframe
          title="Ninja Wallet"
          src={`${process.env.REACT_APP_NINJA_AGNET_URL}/ninjapay?type=collect&appname=ninjapay&code=${code}&invoiceid=${invoiceId}&amount=${amount}&callbackurl=${process.env.REACT_APP_SELF_URL}/nwallet/store/${storeID}/onpayment/${orderID}/${timestamp}`}
          frameBorder="0"
          width="100%"
          height="100%"
          scrolling="yes"
        />
      </div>    
    </>
  );
};
export default NWalletWrapper;
