import { CircularProgress, Container } from "@material-ui/core";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import { container } from "../../styles/common";

export const NAccOnRegistration = () => {
  const { storeID, timestamp } = useParams();
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    localStorage.setItem(`agnet_opened_${storeID}_${timestamp}`, "success");
  }, []);

  return (
    <>
      <Helmet>
        <title>Account Configuration</title>
        <meta charSet="utf-8" />
      </Helmet>
      <Container className={container}>
        {loading && (
          <div
            style={{
              height: "89vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="secondary" />
            <h2
              style={{
                fontSize: 16,
                fontWeight: 400,
                marginTop: 18,
                textAlign: "center",
                lineHeight: "28px",
              }}
            >
              Your account is being configured!
            </h2>
          </div>
        )}
      </Container>
    </>
  );
};
export default NAccOnRegistration;
