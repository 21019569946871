import { style } from "typestyle";

export const layoutContainer = style({
  width: "100%",
});

export const inputTag = style({
  marginLeft: 8,
  borderRadius: 6,
  color: "#1641DB",
  backgroundColor: "#E9EEFF",
  padding: "2px 8px 4px 8px",
});

export const rowFlexAlignCenter = style({
  display: "flex",
  alignItems: "center",
});

export const rowFlexAlignStart = style({
  display: "flex",
  alignItems: "flex-start",
});

export const rowFlexCenterAll = style({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

export const rowFlexJustifyBetween = style({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});

export const rowFlexOnlyJustifyBetween = style({
  display: "flex",
  justifyContent: "space-between",
});

export const rowFlexOnlyJustifyEnd = style({
  display: "flex",
  justifyContent: "flex-end",
});

export const rowFlexOnlyJustifyBetweenCenter = style({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

export const rowFlexOnlyJustifyBetweenStart = style({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "start",
});

export const colFlexJustifyBetween = style({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export const clickable = style({
  cursor: "pointer",
});

export const container = style({
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: 64,
  minHeight: "calc(100vh * var(--device-pixel-ratio))",
});
export const progressbar = style({
  position: "fixed",
  top: "50%",
  left: "50%",
});